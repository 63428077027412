var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:_vm.$style.container},[_c('router-link',{class:_vm.$style.create,attrs:{"to":"marketing-notifications/create"}},[_c('Icon',{class:_vm.$style.plus,attrs:{"name":"plus"}}),_vm._v(" Создать уведомление ")],1),_c('ul',{class:_vm.$style.filter},[_c('li',{class:[_vm.$style.item, { [_vm.$style.active]: 'active' === _vm.activeFilters }],on:{"click":function($event){return _vm.changeFilter('active')}}},[_vm._v(" Активные ")]),_c('li',{class:[_vm.$style.item, { [_vm.$style.active]: 'past' === _vm.activeFilters }],on:{"click":function($event){return _vm.changeFilter('past')}}},[_vm._v(" Прошедшие ")]),_c('li',{class:[_vm.$style.item, { [_vm.$style.active]: 'all' === _vm.activeFilters }],on:{"click":function($event){return _vm.changeFilter('all')}}},[_vm._v(" Все ")])]),(_vm.activeFilters === 'active')?_c('DraggableTable',{attrs:{"rows":_vm.rows,"items":_vm.notificationsList},on:{"drag":function($event){return _vm.handleDrag($event)}},scopedSlots:_vm._u([{key:"default",fn:function(slotProps){return [(slotProps.row.field === 'startAt')?_c('td',[_vm._v(" "+_vm._s(_vm.fomateDateRow(slotProps.item.startAt))+" ")]):(slotProps.row.field == 'endAt')?_c('td',[_vm._v(" "+_vm._s(_vm.fomateDateRow(slotProps.item.endAt))+" ")]):(slotProps.row.field == 'isNews')?_c('td',[_c('div',{class:[
            _vm.$style.status,
            {
              [_vm.$style.successStatus]: slotProps.item.isNews,
            },
          ]})]):(slotProps.row.field == 'forSuppliers')?_c('td',[_c('div',{class:[
            _vm.$style.status,
            {
              [_vm.$style.successStatus]: slotProps.item.forSuppliers,
            },
          ]})]):(slotProps.row.field == 'forDealers')?_c('td',[_c('div',{class:[
            _vm.$style.status,
            {
              [_vm.$style.successStatus]: slotProps.item.forDealers,
            },
          ]})]):(slotProps.row.field == 'forDealersBonusProgramMembers')?_c('td',[_c('div',{class:[
            _vm.$style.status,
            {
              [_vm.$style.successStatus]:
                slotProps.item.forDealersBonusProgramMembers,
            },
          ]})]):(slotProps.row.field == 'forSuppliersBonusProgramMembers')?_c('td',[_c('div',{class:[
            _vm.$style.status,
            {
              [_vm.$style.successStatus]:
                slotProps.item.forSuppliersBonusProgramMembers,
            },
          ]})]):(slotProps.row.field === 'action')?_c('td',[_c('span',[_c('router-link',{attrs:{"to":_vm.getRoute({
                route:
                  _vm.$options.ADDSELLERS_ADMIN_ROUTES.MARKETING_NOTIFICATIONS
                    .EDIT,
                params: { id: slotProps.item.id },
              })}},[_c('Icon',{class:_vm.$style.plus,attrs:{"name":"plus"}})],1)],1)]):(slotProps.row.field === 'delete')?_c('td',[_c('span',{attrs:{"title":"Удалить акцию"},on:{"click":function($event){return _vm.deleteMarketingNotification(slotProps.item)}}},[_c('Icon',{class:_vm.$style.delete,attrs:{"name":"plus"}})],1)]):_vm._e()]}}],null,false,3377732113)}):_c('vue-good-table',{class:_vm.$style.table,attrs:{"columns":_vm.columns,"rows":_vm.notificationsList,"styleClass":"vgt-table striped"},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'startAt')?_c('span',[_vm._v(" "+_vm._s(_vm.fomateDateRow(props.row.startAt))+" ")]):(props.column.field == 'endAt')?_c('span',[_vm._v(" "+_vm._s(_vm.fomateDateRow(props.row.endAt))+" ")]):(props.column.field == 'isNews')?_c('span',[_c('div',{class:[
            _vm.$style.status,
            {
              [_vm.$style.successStatus]: props.row.isNews,
            },
          ]})]):(props.column.field == 'forSuppliers')?_c('span',[_c('div',{class:[
            _vm.$style.status,
            {
              [_vm.$style.successStatus]: props.row.forSuppliers,
            },
          ]})]):(props.column.field == 'forDealers')?_c('span',[_c('div',{class:[
            _vm.$style.status,
            {
              [_vm.$style.successStatus]: props.row.forDealers,
            },
          ]})]):(props.column.field == 'forDealersBonusProgramMembers')?_c('span',[_c('div',{class:[
            _vm.$style.status,
            {
              [_vm.$style.successStatus]: props.row.forBonusProgramMembers,
            },
          ]})]):(props.column.field == 'forSuppliersBonusProgramMembers')?_c('span',[_c('div',{class:[
            _vm.$style.status,
            {
              [_vm.$style.successStatus]: props.row.forBonusProgramMembers,
            },
          ]})]):(props.column.field == 'action')?_c('span',[_c('router-link',{attrs:{"to":`marketing-notifications/edit/${props.row.id}`}},[_c('Icon',{class:_vm.$style.plus,attrs:{"name":"plus"}})],1)],1):(props.column.field == 'delete')?_c('span',{attrs:{"title":"Удалить акцию"},on:{"click":function($event){return _vm.deleteMarketingNotification(props.row)}}},[_c('Icon',{class:_vm.$style.delete,attrs:{"name":"plus"}})],1):_vm._e()]}}])}),(_vm.activeFilters === 'active')?_c('Button',{class:_vm.$style.loadMore,on:{"click":_vm.loadMore}},[_vm._v(" Загрузить еще")]):_vm._e(),(_vm.pageCount > 1 && _vm.activeFilters !== 'active')?_c('Pagination',{attrs:{"curPage":_vm.notificationPage,"lastPage":_vm.pageCount},on:{"go-next":function($event){return _vm.goNext()},"go-prev":function($event){return _vm.goPrev()}}}):_vm._e(),_c('Loader',{attrs:{"isLoading":_vm.isLoading}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }